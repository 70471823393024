<template>
  <div v-if="step == 1">
    <div id="form">
      <div>
        {{$gettext('Per resettare la tua password inserisci il codice che ti abbiamo inviato via email e la nuova password.') }}
      </div>
      <form @submit.prevent="invio">
        <custom-input
          :placeholder='$gettext("Codice di controllo")'
          :label='$gettext("Codice di controllo")'
          :value="codice"
          type="text"
          @input="(val) => (codice = val)"
        />
        <div class="error" v-html="codeErr"></div>
        <custom-input
          placeholder="Password"
          label="password"
          :value="password"
          type="password"
          @input="(val) => (password = val)"
        />
        <div class="error" v-html="passwordErr"></div>
        <custom-input
          :placeholder='$gettext("Conferma password")'
          :label='$gettext("Conferma password")'
          :value="password2"
          type="password"
          @input="(val) => (password2 = val)"
        />
        <div class="error" v-html="passwordErr"></div>
        <div id="button">
          <button :disabled="!sendOk" v-if="!loading">{{$gettext('cambio password')}}</button>
        </div>
      </form>
      <div id="loading" v-if="loading">
        {{$gettext('Richiesta cambio password in corso...')}}
      </div>
      <div id="errore" v-html="errorMsg"></div>
    </div>
  </div>
  <div v-if="step == 2">
    <div id="intro">
      {{ $gettext('La tua password è stata modificata. Ora puoi accedere alla applicazione tramite la pagina di login.') }}
    </div>
  </div>
</template>

<script>
import customInput from '@/components/shared/custom-input';
export default {
  name: 'lost_password_step2',
  components: { customInput },
  data() {
    return {
      codice: '',
      password: '',
      password2: '',
      loading: false,
      codeErr: '',
      passwordErr: '',
      step: 1,
      errorMsg: '',
    };
  },
  methods: {
    async invio() {
      this.loading = true;

      this.codeErr = '';
      this.passwordErr = '';
      this.loading = true;
      this.errorMsg = '';

      try {
        let response = await this.axios.post(this.api.reset_step_2, {
          code: this.codice,
          password: this.password,
        });
      } catch (error) {
        console.log(error.response.data);
        switch (error.response.data.code) {
          case 503:
            break;

          case 504:
            break;
        }
        this.errorMsg = error.response.data.message; //error.response.data.msg;
        this.loading = false;
        return;
      }
      this.loading = false;
      this.step = 2;
    },
  },
  computed: {
    sendOk() {
      if (
        this.loading == true ||
        this.codice.trim() == '' ||
        this.password.trim() == '' ||
        this.password2.trim() == ''
      )
        return false;
      if (this.password2.trim() != this.password.trim()) {
        this.passwordErr = $gettext('Le due password non coincidono');
        return false;
      } else {
        this.passwordErr = '';
      }
      return true;
    },
  },
};
</script>

<style scoped>
#intro {
  padding: 20px;
}

.error {
  font-weight: 600;
  letter-spacing: 1px;
  width: var(--var-width-field);
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}
</style>
